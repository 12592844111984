import { formatDate } from '@stellacontrol/utilities'
import { AlertDefinition, AlertOccurrence, AlertSubscription, AlertConfiguration, AlertTypes, Organization, Device, NotificationRecipient, AlertStatistics, AlertStatisticsPart, BandPowerStatistics, BandUsageStatistics, DeviceAlertStatistics } from '@stellacontrol/model'
import { APIClient } from './api-client'

const LONG_TIMEOUT = 5 * 60 * 1000

/**
 * Alerts API client
 */
export class AlertsAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Alerts'
  }

  /**
   * Retrieves organizations which are subscribing to alerts
   */
  // TODO: OBSOLETE
  async getMonitoredOrganizations () {
    try {
      const method = 'get'
      const url = this.endpoint('organization')
      const { organizations } = await this.request({ method, url, timeout: LONG_TIMEOUT })
      return this.asOrganizations(organizations)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves organization which is subscribing to alerts,
   * with all other details such as observed devices and users to notify
   * @param organizationId Organization identifier
   */
  // TODO: OBSOLETE
  async getMonitoredOrganization ({ organizationId } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('organization', organizationId)
      const { organization, devices } = await this.request({ method, url })
      return {
        organization: this.asOrganization(organization),
        devices: this.asDevices(devices)
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves monitored devices, optionally only those
   * associated with the specified organization
   * @param organizationId Organization identifier
   */
  // TODO: OBSOLETE
  async getMonitoredDevices ({ organizationId } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device')
      const params = { organizationId }
      const { devices } = await this.request({ method, url, params, timeout: LONG_TIMEOUT })
      return this.asDevices(devices)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves a complete hierarchy of monitored devices
   * grouped by organizations and places,
   * starting with the specified organization
   * @param organizationId Organization identifier
   * @param withAlertConfiguration If true, configurations of monitored alerts are retrieved
   */
  // TODO: OBSOLETE
  async getMonitoredDevicesHierarchy ({ organizationId, withAlertConfiguration } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device', 'hierarchy')
      const params = { organizationId, withAlertConfiguration }
      const { hierarchy } = await this.request({ method, url, params, timeout: LONG_TIMEOUT })
      return this.asMonitoredDevicesHierarchy(hierarchy)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves alert definitions for monitored devices,
   * optionally only those associated with the specified organization and/or device
   * @param {String} organizationId Organization identifier
   * @param {String} deviceId Device identifier
   * @returns {Promise<Array[AlertDefinition]>}
   */
  async getMonitoredAlerts ({ organizationId, deviceId } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('alert')
      const params = { organizationId, deviceId }
      const { alerts } = await this.request({ method, url, params, timeout: LONG_TIMEOUT })
      return this.asAlertDefinitions(alerts)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns a list of recipients who will be notified about alerts
   * related to the specified device
   * @param {String} serialNumber Device serial number
   * @param {AlertType} alertType Triggered alert type. Recipient will only be returned
   * if he hasn't muted the specified alert type for the device
   * @returns {Promise<Array[NotificationRecipient]>} Alert recipients
   */
  async getAlertRecipients ({ serialNumber, alertType } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'recipients', serialNumber, alertType)
      const { recipients } = await this.request({ method, url })
      return this.asNotificationRecipients(recipients)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves details of a monitored device,
   * together with its alerts etc.
   * @param id Device identifier
   * @param serialNumber Alternative to ID, device identifier
   */
  async getMonitoredDevice ({ id, serialNumber } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device', id ? '' : 'serial-number', id || serialNumber)
      const { device, alerts } = await this.request({ method, url })
      return {
        device: this.asDevice(device),
        alerts
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns a list of alert occurrences matching the conditions
   * @param organization Owner organization - if specified, only alerts associated with this organization will be returned
   * @param device Owner device - if specified, only alerts associated with this device will be returned
   * @param alertType Alert type - if specified, only alerts matching this type will be returned
   * @param maxAge Maximal age of alerts, in seconds - if specified, only alerts not older than this will be returned
   */
  async getAlertOccurrences ({ organization, device, alertType, maxAge }) {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'occurrence')
      const params = {
        organizationId: organization ? organization.id : undefined,
        deviceId: device ? device.id : undefined,
        alertType,
        maxAge
      }
      const { alerts } = await this.request({ method, url, params })
      return this.asAlertOccurrences(alerts)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns the most recent occurrence of alert matching the conditions
   * @param {Organization} organization Owner organization - if specified, only alerts associated with this organization will be returned
   * @param {Device} device Owner device - if specified, only alerts associated with this device will be returned
   * @param {AlertType} alertType Alert type - if specified, only alerts matching this type will be returned
   * @param {Number} maxAge Maximal age of alert, in seconds - if specified, only alerts not older than this will be returned
   * @param {Number} count Maximal number of the recent alerts to return
   * @returns {Promise<Array[AlertOccurrence]>}
   */
  async getRecentAlertOccurrences ({ organization, device, alertType, maxAge, count }) {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'occurrence', 'last')
      const params = {
        organizationId: organization ? organization.id : undefined,
        deviceId: device ? device.id : undefined,
        alertType,
        maxAge,
        count
      }
      const { alerts } = await this.request({ method, url, params })
      return this.asAlertOccurrences(alerts)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves alert occurrence in a database
   * @param alert Alert occurrence to save
   */
  async saveAlertOccurrence ({ alert } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('alert', 'occurrence')
      const data = alert
      const { alert: savedAlert } = await this.request({ method, url, data })
      return this.asAlertOccurrence(savedAlert)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves alert configurations for the specified device
   * @param {Device} device Device whose alert configurations to return
   * @returns {Promise<Array[AlertConfiguration>]}
   */
  async getDeviceAlertConfigurations ({ device } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('device', device.id, 'configuration')
      const { configurations } = await this.request({ method, url })
      return configurations?.map(configuration => this.asAlertConfiguration(configuration))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves system-wide default alert configurations
   * @returns {Dictionary<AlertType, AlertConfiguration>} Default alert configurations
   */
  async getDefaultAlertConfigurations () {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'configuration', 'default')
      const { alertConfigurations } = await this.request({ method, url })
      for (const alertType of AlertTypes) {
        alertConfigurations[alertType] = this.asAlertConfiguration(alertConfigurations[alertType])
      }
      return alertConfigurations
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves alert configuration
   * @param id Configuration identifier
   */
  async getAlertConfiguration ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'configuration', id)
      const { alertConfiguration } = await this.request({ method, url })
      return alertConfiguration
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes alert configurations
   * Deletes alert configurations
   * @param organization If specified, only configurations applying to devices belonging to this organization are deleted
   * @param place If specified, only configurations applying to devices assigned to this place are deleted
   * @param device If specified, only configurations applying to the specified device are deleted
   * @param alertType If specified, alerts to delete are additionally filtered by type
   */
  async deleteAlertConfigurations ({ organization, device, place, alertType }) {
    try {
      const method = 'delete'
      const url = this.endpoint('alert', 'configuration')
      const params = {
        organizationId: organization ? organization.id : undefined,
        placeId: place ? place.id : undefined,
        deviceId: device ? device.id : undefined,
        alertType
      }
      await this.request({ method, url, params })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * deletes alert configuration
   * @param id Configuration identifier
   */
  async deleteAlertConfiguration ({ id }) {
    try {
      const method = 'delete'
      const url = this.endpoint('alert', 'configuration', id)
      await this.request({ method, url })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves the alert configuration
   * @param alertConfiguration Alert configuration to save
   */
  async saveAlertConfiguration ({ alertConfiguration: data }) {
    try {
      const method = 'post'
      const url = this.endpoint('alert', 'configuration')
      const { alertConfiguration } = await this.request({ method, url, data })
      return alertConfiguration
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves configuration of alerts for the specified device
   * @param {Array[AlertConfiguration]} configurations List of alert configurations to save
   * @param {Array[Device]} devices Device whose alert configurations are being saved
   * @returns {Promise<Array[AlertConfiguration]>} Saved alert configurations
   */
  async saveDeviceAlertConfigurations ({ configurations = [], devices = [] } = {}) {
    try {
      const method = 'put'
      const url = this.endpoint('device', 'configuration')
      const data = {
        devices: devices.map(d => ({ id: d.id })),
        configurations
      }
      const result = await this.request({ method, url, data })
      return result?.configurations?.map(configuration => this.asAlertConfiguration(configuration))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Resolves alert configurations for the specified device
   * @param device Device whose alert configurations should be returned
   */
  async getDeviceConfigurations ({ device }) {
    if (!device) return []

    try {
      const method = 'get'
      const url = this.endpoint('device', device.id, 'configuration')
      const { configurations } = await this.request({ method, url })
      return configurations
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Resolves alert configurations for the specified devices
   * @param {Array[Device]} devices Configurations applying to the specified device are returned.
   * @returns {Array[{ device: Device, configurations: Array[AlertConfiguration]}]>}
   */
  async getDevicesConfigurations ({ devices = [] }) {
    if (!(devices?.length > 0)) return []

    try {
      const method = 'post'
      const url = this.endpoint('device', 'configuration')
      const data = {
        devices: devices.map(({ id, serialNumber }) => ({ id, serialNumber }))
      }
      const { configurations } = await this.request({ method, url, data, timeout: LONG_TIMEOUT })
      return configurations
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns alert statistics for the specified organization
   * @param {Organization} organization Organization whose alert statistics to return
   * @param {Date} from Period start
   * @param {Date} until Period end
   * @param {Boolean} details If true, also details are returned, such as list of alerts, list of failed devices etc.
   * @param {Boolean} all If true, statistics of the entire customer hierarchy of the organization are returned
   * @param {Array[AlertStatisticsPart]} parts Parts of the statistics to retrieve
   * @returns {Promise<Array[AlertStatistics]>}
   */
  async getAlertStatistics ({ organization, from, until, all, details, parts } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'statistics')
      const params = {
        organization: organization?.id,
        from: formatDate(from, 'yyyy-MM-dd'),
        until: formatDate(until, 'yyyy-MM-dd'),
        all: Boolean(all),
        details: Boolean(details),
        parts: parts || AlertStatisticsPart.All
      }
      const { statistics } = await this.request({ method, url, params })
      return statistics?.map(item => new AlertStatistics(item))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes alert occurrence
   * @param {AlertOccurrence} alert Alert to delete
   * @returns {Promise<AlertOccurrence>} Deleted alert occurrence
   */
  async deleteAlertOccurrence ({ alert }) {
    try {
      const method = 'delete'
      const url = this.endpoint('alert', 'occurrence', alert.id)
      const { alert: deletedAlert } = await this.request({ method, url })
      return this.asAlertOccurrence(deletedAlert)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes alert occurrences
   * @param {Array[AlertOccurrence]} alerts Alerts to delete
   * @returns {Promise<Number>} The number of deleted alert occurrences
   */
  async deleteAlertOccurrences ({ alerts = [] }) {
    try {
      if (alerts?.length > 0) {
        const method = 'delete'
        const url = this.endpoint('alert', 'occurrence')
        const data = {
          alerts: alerts.map(alert => alert.id)
        }
        const { deletedAlerts } = await this.request({ method, url, data })
        return deletedAlerts
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the alert statistics of a specified device
   * @param {Device} device Device whose alert statistics to get
   * @param {String} name Name of the statistics to retrieve, for example 'sustained-reduced-power'
   * @returns {Promise<DeviceAlertStatistics>} Alert statistics for the device
   */
  async getDeviceAlertStatistics ({ device, name }) {
    try {
      const method = 'get'
      const url = this.endpoint('device', device.id, 'statistics', name)
      const { statistics } = await this.request({ method, url })
      return this.asDeviceAlertStatistics(statistics)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the alert statistics of a specified device
   * @param {Device} device Device whose alert statistics to get
   * @param {String} name Name of the statistics to delete, for example 'sustained-reduced-power'
   * @returns {Promise<DeviceAlertStatistics>} Alert statistics for the device
   */
  async deleteDeviceAlertStatistics ({ device, name }) {
    try {
      const method = 'delete'
      const url = this.endpoint('device', device.id, 'statistics', name)
      const { statistics } = await this.request({ method, url })
      return this.asDeviceAlertStatistics(statistics)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves information about alerts occurring in the specified buildings
   * @param {Place} places list of places whose alert details to return
   * @param {Number} days number of days to filter alerts
   * @param {Boolean} details wether to display details or not
   * @returns {Promise<Object>}
   */

  async getBuildingsAlerts ({ places, days = 1, details = true }) {
    try {
      const method = 'post'
      const url = this.endpoint('alert', 'buildings')
      const data = {
        places: places.flatMap(place => [
          {
            id: place.id,
            organizationId: place.organizationId
          },
          {
            id: 'none',
            organizationId: place.organizationId
          }
        ])
      }

      const maxAge = days * 24 * 60 * 60
      const { organizations } = await this.request({ method, url, data, params: { maxAge, details } }) || {}

      if (details) {
        for (const organization of Object.values(organizations || {})) {
          for (const place of Object.values(organization.places || {})) {
            place.alerts = place.alerts.map(a => this.asAlertOccurrence(a))
          }
        }
      }

      return organizations

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves information about alerts occurring in the specified building
   * @param {String} organizationId Id of organization
   * @param {String} placeId Id of Place
   * @param {Number} days number of days to filter alerts
   * @param {Boolean} details wether to display details or not
   * @returns {Promise<Object>}
   */

  async getBuildingAlerts ({ organizationId, placeId, days = 1, details = true }) {
    try {
      const method = 'get'
      const url = this.endpoint('alert', 'organization', organizationId, 'building', placeId)
      const maxAge = days * 24 * 60 * 60
      const { organizations = {} } = await this.request({ method, url, params: { maxAge, details } }) || {}

      const alerts = (organizations[organizationId]?.places[placeId]?.alerts || [])
        .map(a => this.asAlertOccurrence(a))
      return alerts

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Converts the specified data item
   * received from API to Device instance
   * @param item Data item
   * @returns Device instance initialized with the content of the data item
   */
  asDevice (item) {
    if (item) {
      return new Device(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to Device instances
   * @param items Data items
   * @returns Device instances initialized with the content of the data items
   */
  asDevices (items = []) {
    return items.map(item => new Device(item))
  }

  /**
   * Converts the specified data item
   * received from API to AlertOccurrence instance
   * @param item Data item
   * @returns Alert instance initialized with the content of the data item
   */
  asAlertOccurrence (item) {
    if (item) {
      return new AlertOccurrence(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to AlertOccurrence instances
   * @param item Data items
   * @returns Alert instances initialized with the content of the data items
   */
  asAlertOccurrences (items) {
    if (items) {
      return items.map(item => new AlertOccurrence(item))
    }
  }

  /**
   * Converts the specified data item
   * received from API to AlertDefinition instance
   * @param item Data item
   * @returns Alert instance initialized with the content of the data item
   */
  asAlertDefinition (item) {
    if (item) {
      return new AlertDefinition(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to AlertDefinition instances
   * @param items Data items
   * @returns Alert instances initialized with the content of the data items
   */
  asAlertDefinitions (items = []) {
    return items.map(item => new AlertDefinition(item))
  }

  /**
   * Converts the specified data item to Organization instance
   * @param item Data item
   */
  asOrganization (item) {
    if (item) {
      return new Organization(item)
    }
  }

  /**
   * Converts the specified data items to Organization instances
   * @param items Data items
   */
  asOrganizations (items = []) {
    return items.map(item => this.asOrganization(item))
  }

  /**
   * Converts the specified data item to NotificationRecipient instance
   * @param item Data item
   */
  asNotificationRecipient (item) {
    if (item) {
      return new NotificationRecipient(item)
    }
  }

  /**
   * Converts the specified data items to NotificationRecipient instances
   * @param items Data items
   */
  asNotificationRecipients (items = []) {
    return items.map(item => this.asNotificationRecipient(item))
  }

  /**
   * Converts the specified data item to AlertSubscription instance
   * @param item Data item
   */
  asAlertSubscription (item) {
    if (item) {
      return new AlertSubscription(item)
    }
  }

  /**
   * Converts the specified data items to AlertSubscription instances
   * @param items Data items
   */
  asAlertSubscriptions (items = []) {
    return items.map(item => this.asAlertSubscription(item))
  }

  /**
   * Converts the specified data item to AlertConfiguration instance
   * @param item Data item
   */
  asAlertConfiguration (item) {
    if (item) {
      return new AlertConfiguration(item)
    }
  }

  /**
   * Converts the specified data items to AlertConfiguration instances
   * @param items Data items
   */
  asAlertConfigurations (items = []) {
    return items.map(item => this.asAlertConfiguration(item))
  }

  /**
   * Typecasts some of the entities in monitored device hierarchy
   */
  asMonitoredDevicesHierarchy (hierarchy) {
    if (hierarchy) {
      if (hierarchy.defaultConfigurations) {
        for (const [alertType, configuration] of Object.entries(hierarchy.defaultConfigurations)) {
          hierarchy.defaultConfigurations[alertType] = new AlertConfiguration(configuration)
        }
      }

      return hierarchy
    }
  }

  /**
   * Typecasts some of the entities into DeviceAlertStatistics or subclass of it
   */
  asDeviceAlertStatistics (item, name) {
    if (item) {
      if (name === 'sustained-reduced-power') return new BandPowerStatistics(item)
      if (name === 'band-usage') return new BandUsageStatistics(item)
      return new DeviceAlertStatistics(item)
    }
  }
}
