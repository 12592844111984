<script>
import { countString } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { PlanActions, createPlanAction } from '../../renderer'
import Menu from './menu'

export default {
  mixins: [
    Menu,
    Secure
  ],

  computed: {
    // Plan items for which to show the menu
    items () {
      return this.menu?.items || []
    },

    // Single item to show the menu for
    item () {
      return this.menu?.items ? this.menu?.items[0] : undefined
    },

    // Indicates whether the menu is visible
    isVisible () {
      return this.position != null && this.item != null
    },

    // Menu items
    actions () {
      const { items, item, isAdvancedMode, isEditable } = this
      const canCopy = isEditable && items.some(item => item.canClone)
      const canRemove = isEditable && items.some(item => item.canRemove)
      const canReorder = isEditable && isAdvancedMode && items.some(item => item.canReorder)
      const canFlip = isEditable && items.some(item => item.canFlip || item.canRotate)
      const canShowDetails = items.length === 1 && item.hasDetails

      const actions = [
        // Show item details
        canShowDetails ? { action: PlanActions.ShowItemDetails } : null,
        // Duplicate
        canCopy ? { action: PlanActions.DuplicateItems, message: `${countString(items, 'item')} duplicated` } : null,
        // Copy to clipboard
        canCopy ? { action: PlanActions.CopyItems, message: `${countString(items, 'item')} copied to clipboard` } : null,
        // Rotate
        canFlip ? { action: PlanActions.RotateItems, args: { angle: 45, keepMenu: true } } : null,
        // Reorder
        canReorder ? { action: PlanActions.BringToTop, separator: true } : null,
        canReorder ? { action: PlanActions.BringToBottom } : null,
        // Remove items
        canRemove ? { action: PlanActions.RemoveItems, separator: true } : null
      ]

      return actions
        .filter(item => item)
        .map(({ action, message, separator, label, icon, color = 'grey-2', args, ...options }) => ({
          separator,
          action: action ? createPlanAction({ action, message, items, label, icon, color, args, ...options }) : null
        }))
    },

    // Indicates whether any actions are available
    hasActions () {
      return this.actions.length > 0
    }
  }
}

</script>

<template>
  <div ref="menu" class="menu bg-grey-9 shadow-2 rounded-borders q-pa-sm" :class="cssClass"
    :style="{ ...cssStyle, display: hasActions ? undefined : 'none' }">
    <div class="row items-center no-wrap">
      <template v-for="{ separator, action } in actions">
        <div v-if="separator" class="separator">
          <div></div>
        </div>
        <q-btn :icon="action.icon" :color="action.color" flat unelevated dense size="sm"
          :class="buttonClass(action)" @click="execute({ action, items })">
          <sc-tooltip>
            {{ action.label }}
          </sc-tooltip>
        </q-btn>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  position: absolute;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }

  .separator {
    padding-left: 5px;
    padding-right: 5px;
    height: 20px;

    div {
      width: 1px;
      height: 100%;
      background-color: gray;
    }
  }

  button.action {
    margin-left: 4px;
  }
}
</style>
