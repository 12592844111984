<script>
import { mapState, mapActions } from 'vuex'
import { ViewMixin, TabsMixin } from '@stellacontrol/client-utilities'
import { DeviceFirmwareGroupBy } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import Firmwares from './components/firmwares.vue'
import UploadJobs from './components/upload-jobs.vue'
import FirmwareHistory from './components/firmware-history.vue'
import { resolveJobUploads, resolveDeviceFirmwares } from './device-updates.resolve'

const name = 'device-updates'

export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    Secure
  ],

  components: {
    'sc-firmwares': Firmwares,
    'sc-upload-jobs': UploadJobs,
    'sc-firmware-history': FirmwareHistory
  },

  data () {
    return {
      name,
      showObsoleteFirmwares: false,
      DeviceFirmwareGroupBy,
      days: [
        { value: 1, label: 'Last 24 hours' },
        { value: 7, label: 'Last 7 days' },
        { value: 30, label: 'Last 30 days' },
        { value: 90, label: 'Last 90 days' },
        { value: 0, label: 'All uploads' }
      ]
    }
  },

  computed: {
    ...mapState({
      // Firmwares available to currently logged in organization
      firmwares: state => state.deviceTransmission.firmwares,
      // Firmwares grouping mode
      groupFirmwaresBy: state => state.deviceTransmission.groupFirmwaresBy,
      // Number of days of jobs to load
      jobDays: state => state.deviceTransmission.jobDays || 0,
      // Jobs initiated by the currently logged in organization
      jobs: state => state.deviceTransmission.jobs
    }),

    // Selected value for job days
    selectedDays () {
      const days = this.days.find(d => d.value === this.jobDays)
      return days
    },

    // Returns true if current user is allowed to manage firmware repository
    canManageFirmware () {
      return this.canUse('can-manage-firmware')
    },

    // Returns true if there any firmwares in the repository
    hasFirmwares () {
      return (this.firmwares || []).length > 0
    },

    // Returns true if there any upload jobs
    hasUploadJobs () {
      return (this.jobs || []).length > 0
    },

    showFirmwaresFilter () {
      return this.canManageFirmware && this.isViewTab('firmware')
    },

    showUploadsFilter () {
      return this.isViewTab('upload-jobs')
    },

    showAddFirmwareButton () {
      return this.isViewTab('firmware') && this.canManageFirmware
    }
  },

  watch: {
    viewTab (value) {
      this.tabSelected(value)
    }
  },

  methods: {
    ...mapActions([
      'updateRoute',
      'createDeviceFirmware',
      'watchUploadStatus',
      'unwatchUploadStatus',
      'groupDeviceFirmwaresBy'
    ]),

    tabSelected () {
      if (this.isViewTab('upload-jobs')) {
        this.watchUploadStatus()
      } else {
        this.unwatchUploadStatus()
      }
    },

    // Reloads the job list, to show the specified amount of days
    showJobDays (days) {
      if (days === 0) {
        this.updateRoute({ query: { days: undefined } })
      } else {
        this.updateRoute({ query: { days } })
      }
    }
  },

  async beforeRouteUpdate (to, from) {
    switch (this.viewTab) {
      case 'upload-jobs':
        await resolveJobUploads({ to, from })
        break
      case 'firmware':
        await resolveDeviceFirmwares({ from, to })
        break
    }
  },

  async beforeUnmount () {
    // Stop any running status polling
    await this.unwatchUploadStatus()
  },

  created () {
    if (this.isViewTab('upload-jobs')) {
      this.watchUploadStatus()
    }
  }
}

</script>

<template>
  <sc-view :name="name">
    <template #toolbar>
      <q-btn label="Add Firmware" v-if="showAddFirmwareButton" icon="add" class="primary" unelevated
        @click="createDeviceFirmware()"></q-btn>
    </template>

    <template #header>
    </template>

    <sc-tabs :model-value="viewTab" @update:model-value="tab => selectTab(tab)" :tabs="viewTabs">
      <q-tab name="upload-jobs" icon="motion_photos_on" label="Upload Jobs" :ripple="false"></q-tab>
      <q-tab v-if="canManageFirmware" name="firmware" icon="memory" label="Firmwares"
        :ripple="false"></q-tab>
      <q-tab name="firmware-history" icon="list" label="Change Log" :ripple="false"></q-tab>
      <q-space></q-space>
      <div class="filters bg-indigo-1">
        <sc-list-filter name="upload-jobs" v-if="showUploadsFilter" transparent>
          <div class="days q-ml-lg row just-fy-end items-center">
            <q-select class="q-ml-md" :style="{ width: '130px' }" dense emit-value borderless
              :model-value="selectedDays" :options="days" map-options option-value="id"
              option-label="label" @update:model-value="({ value }) => showJobDays(value)">
            </q-select>
          </div>
        </sc-list-filter>

        <sc-list-filter name="firmwares" v-if="showFirmwaresFilter"
          transparent>
          <div class="group-by q-ml-lg row justify-end items-center">
            <q-checkbox dense v-model="showObsoleteFirmwares" label="Show Old Firmwares" class="q-mr-md primary">
            </q-checkbox>
            <q-btn-dropdown unelevated dense no-caps label="Group by" :ripple="false">
              <q-list>
                <q-item clickable v-close-popup
                  :active="groupFirmwaresBy === DeviceFirmwareGroupBy.Version"
                  @click="groupDeviceFirmwaresBy({ groupBy: DeviceFirmwareGroupBy.Version })">
                  <q-item-section>
                    <q-item-label>
                      Firmware Version
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup
                  :active="groupFirmwaresBy === DeviceFirmwareGroupBy.DeviceModel"
                  @click="groupDeviceFirmwaresBy({ groupBy: DeviceFirmwareGroupBy.DeviceModel })">
                  <q-item-section>
                    <q-item-label>
                      Device Model
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </sc-list-filter>
      </div>
    </sc-tabs>

    <sc-tab-panels :model-value="viewTab" :tabs="viewTabs">
      <q-tab-panel name="upload-jobs">
        <sc-upload-jobs></sc-upload-jobs>
      </q-tab-panel>
      <q-tab-panel name="firmware" v-if="canManageFirmware">
        <sc-firmwares :showObsoleteFirmwares="showObsoleteFirmwares"></sc-firmwares>
      </q-tab-panel>
      <q-tab-panel name="firmware-history">
        <sc-firmware-history></sc-firmware-history>
      </q-tab-panel>
    </sc-tab-panels>
  </sc-view>
</template>

<style lang='scss' scoped>
:deep(.q-tab-panel) {
  padding: 0;
}

.group-by {
  height: 100%;
}

.filters {
  min-height: 72px;
}
</style>
