import { Viewport } from '../../responsive'

/**
 * Client state factory
 */
export function createState () {
  return {
    id: (new Date()).getTime(),

    // Client origin
    origin: {
      // Host from which the client is loaded
      host: '',
      // Domain part of the host
      domain: ''
    },

    // Application package details,
    // set by `setApplication` mutation
    pkg: null,

    // Application details - client name, version and description,
    // from package.json or overwritten with domain-specific configuration
    application: {
      isApplet: false,
      name: '',
      version: '',
      description: '',
      url: ''
    },

    // Global configuration
    configuration: null,

    // Environment
    environment: null,

    // Application flags
    flags: null,

    // Indicates that the client has been initialized
    isInitialized: false,

    // Indicates that the client is being unloaded, i.e. due to browser window close
    isUnloading: false,

    // Indicates that we're now redirecting to another site
    redirecting: {
      message: '',
      url: ''
    },

    // Indicates that we're now performing a full reload
    reloading: {
      message: '',
      url: ''
    },

    // Indicates that client initialization has failed
    initializationFailed: false,

    // Long-lasting activity in the client,
    // such as saving a record, retrieving lots of data etc.
    busy: {
      // Indicates that the client is busy now
      now: false,
      // Indicates that the client is busy loading data for a view etc.
      isLoading: false,
      // Start time of the activity
      startedAt: null,
      // End time of the activity
      finishedAt: null,
      // Activity duration in milliseconds
      duration: null,
      // Action with which we're busy
      action: null,
      // Custom message describing the activity
      message: null,
      // More detailed description of the activity
      details: null,
      // Warning message registered at the end of the activity
      warning: null,
      // Error message registered at the end of the activity
      error: null,
      // Data associated with the activity
      data: null,
      // Callback allowing to hide the notification about the activity
      hideNotification: null
    },

    // Last unrecoverable error
    error: {
      // User-friendly message
      message: null,
      // The actual unhandled exception
      exception: null
    },

    // One-second ticker, useful for many things
    ticker: 0,

    // Indication of ad blocker active on the page
    adBlockActive: false
  }
}

/**
 * Client state
 */
export const state = createState()

export const getters = {
  /**
   * Indicates that client has been initialized.
   */
  isInitialized: state =>
    state.isInitialized,

  /**
   * Indicates that client is a single applet, not the entire application.
   * Normally this is used only in development!
   */
  isApplet: state =>
    state.application?.isApplet,

  /**
   * Indicates that client is now redirecting to another URL
   */
  isRedirecting: state =>
    Boolean(state.redirecting.url),

  /**
   * Indicates that client is now performing a full reload
   */
  isReloading: state =>
    Boolean(state.reloading.url),

  /**
   * Indicates that client initialization failed
   */
  initializationFailed: state =>
    state.initializationFailed,

  /**
   * Returns application environment
   */
  environment: state =>
    state.environment,

  /**
   * Returns client origin
   */
  origin: state =>
    state.origin,

  /**
   * Returns application package details
   */
  pkg: state =>
    state.pkg,

  /**
   * Returns application details
   */
  application: state =>
    state.application,

  /**
   * Indicates that currently working in development environment
   */
  isDevelopmentEnvironment: state =>
    state.environment === 'development',

  /**
   * Indicates that currently working in beta environment
   */
  isBetaEnvironment: state =>
    state.environment === 'beta',

  /**
   * Indicates that currently working in production environment
   */
  isProductionEnvironment: state =>
    state.environment === 'production',

  /**
   * Returns application configuration
   */
  configuration: state =>
    state.configuration,

  /**
   * Returns application flags
   */
  flags: state =>
    state.flags,

  /**
   * Returns default site from configuration
   */
  defaultSite: state =>
    state.configuration.sites.find(site => site.isDefault),

  /**
   * Returns company details
   */
  company: state =>
    state.configuration ? state.configuration.company : {},

  /**
   * Returns application branding configuration
   */
  branding: state =>
    state.configuration ? state.configuration.branding : {},

  /**
   * Indicates an unrecoverable error
   */
  isError: state =>
    Boolean(state.error.exception || state.error.message),

  /**
   * Indicates that client is currently busy with a long-lasting activity
   */
  isBusy: state =>
    state.busy.now,

  /**
   * Checks whether client is currently busy with the specified long-lasting activity
   */
  isBusyWith: state =>
    action => state.busy.now && state.busy.action === action,

  /**
   * Indicates that client is currently busy loading some data for the view,
   * so the view cannot be completely rendered or used yet.
   */
  isLoading: state =>
    state.busy.isLoading,

  /**
   * One-second ticker, useful for many things
   */
  ticker: state => state.ticker,

  /**
   * Returns the specified API client
   */
  api: state => name => state.api[name],

  /**
   * Returns a list of all registered API clients
   */
  apis: state => Object.values(state),

  /**
   * Returns true if UI is running on mobile phone
   * which limits the available functionality and data
   */
  isMobilePhone: () => Viewport.isMobilePhone,

  /**
    * Returns true if UI is running on a small screen
    * which requires some adjustments to render the content correctly
    */
  isSmallScreen: () => Viewport.isSmallScreen,

  /**
   * Returns true if UI is running on a large screen
   * allowing full view
   */
  isNormalScreen: () => Viewport.isNormalScreen,

  /**
   * Returns true if UI is running HD Screen
   */
  atLeastHD: () => Viewport.atLeastHD,

  /**
   * Returns true if UI is running HD Plus Screen
   */
  atLeastHDPlus: () => Viewport.atLeastHDPlus,

  /**
   * Returns true if UI is running at least the Full HD Screen
   */
  atLeastFullHD: () => Viewport.atLeastFullHD
}
