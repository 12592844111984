<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // Action icon
    actionIcon: {
      type: String,
      default: 'more_vert'
    },

    // Action icon color
    actionIconColor: {
      type: String,
      default: 'grey-6'
    },

    // Render the widget without any chrome
    // - no border, padding, bg color etc.
    // Useful when widgets are used elsewhere,
    // outside the device dashboard
    noChrome: {
      type: Boolean,
      default: false
    },

    // Widget background color
    bgColor: {
      type: String,
      default: 'white'
    },

    // Widget padding
    padding: {
      type: String,
      default: '4px'
    },

    // Widget width
    width: {
      type: String
    },

    // Minimal widget width
    minWidth: {
      type: String
    },

    // Maximal widget width
    maxWidth: {
      type: String
    },

    // Widget height
    height: {
      type: String
    },

    // Minimal widget height
    minHeight: {
      type: String
    },

    // Actions
    actions: {
      type: Array,
      default: () => []
    },

    // Widget tooltip
    tooltip: {
      type: String
    }
  },

  data () {
    return {
      dummy: null
    }
  },

  computed: {
    ...mapGetters([
      'guardian'
    ]),

    // List of actions permitted
    // for the currently logged in user
    allowedActions () {
      return this
        .actions
        .filter(action => action && this.isActionAllowed(action))
    },

    // Returns true if any actions are permitted on the widget
    hasActions () {
      return this.allowedActions.length > 0
    },

    // Widget CSS class
    widgetClass () {
      if (this.noChrome) {
        return {
          nochrome: true
        }
      } else {
        const { bgColor } = this
        return {
          nochrome: false,
          ['bg-' + bgColor]: bgColor[0] !== '#'
        }
      }
    },

    // Widget CSS style
    widgetStyle () {
      if (this.noChrome) {
        return {
          'background-color': 'transparent'
        }
      } else {
        const { bgColor, padding, width, height, minWidth, minHeight, maxWidth } = this
        return {
          'background-color': bgColor,
          padding,
          width,
          height,
          'min-width': minWidth,
          'max-width': maxWidth,
          'min-height': minHeight
        }
      }
    }
  },

  methods: {
    // Checks whether the specified action
    // is allowed for the current user
    isActionAllowed (action) {
      return !(action.isEnabled === false) && this.guardian.canUseAll(action.permissions)
    },

    // Executes the action
    executeAction (action) {
      this.$emit('action', action)
    }
  }
}
</script>

<template>
  <div flat square :class="{ widget: true }" :style="widgetStyle">

    <slot>
    </slot>

    <sc-tooltip v-if="tooltip" :text="tooltip" anchor="bottom middle"
      self="center middle"></sc-tooltip>
    <div class="actions" v-if="hasActions">
      <q-btn class="action-dropdown no-label" size="14px" padding="2px" flat dense rounded
        :icon="actionIcon" :color="actionIconColor" @click.stop="() => { }">

        <q-popup-edit square :style="{ padding: 0 }" :cover="false" :model-value="dummy">
          <q-list>
            <template v-for="action in allowedActions">
              <q-separator v-if="action.separator"></q-separator>
              <q-item clickable v-close-popup @click.stop="executeAction(action)">
                <q-item-section side v-if="action.icon">
                  <q-icon :name="action.icon" :color="action.color" size="24px"></q-icon>
                </q-item-section>
                <q-item-section v-if="action.label">
                  <q-item-label>{{ action.label }} </q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </q-popup-edit>

      </q-btn>
    </div>

  </div>
</template>

<style lang="scss">
.widget {
  position: relative;

  &.text-widget,
  &.parameter-widget,
  &.icon-widget {
    width: 230px !important;
    min-height: 150px !important;
    overflow: hidden;
  }

  .actions {
    position: absolute;
    right: 0;
    top: 4px;

    .action-dropdown.no-label {
      .q-btn-dropdown__arrow {
        margin-left: 0 !important;
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .widget {
    position: relative;
    width: 100% !important;
    min-height: auto !important;
    max-width: auto !important;

    &.text-widget,
    &.parameter-widget,
    &.icon-widget {
      min-width: 49% !important;
      width: 49% !important;
      min-height: auto !important;
      max-width: auto !important;
    }
  }
}

.widget-content {
  display: flex;
  flex-direction: row;
  padding: 10px;

  > .icon {
    flex: 0;
    padding-right: 10px;
  }

  > .lines {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;

    > span {
      color: #2a2a2a !important;
      width: 100%;
    }

    > span,
    > a {
      font-size: 16px;

      &.label {
        font-weight: 500 !important;
      }

      &.highlight {
        color: #388e3c !important;
      }

      &.muted {
        color: #808080 !important;
      }

      &.warning {
        color: orangered !important;
      }

      &.bold {
        font-weight: bold !important;
      }

      &.huge {
        font-size: 32px !important;
      }

      &.big {
        font-size: 18px !important;
      }

      &.small {
        font-size: 14px !important;
      }

      &.italic {
        font-style: italic;
      }

      &.tiny {
        font-size: 12px !important;
      }

      &.tooltip {
        cursor: pointer;
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .widget-content {
    padding: 4px;

    > .icon {
      padding-right: 8px;
    }

    > .lines {
      span,
      a {
        font-size: 14px;
        line-height: 1.4;

        &.small {
          font-size: 13px !important;
        }

        &.tiny {
          font-size: 11px !important;
        }
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .widget-content {
    > .lines {
      span,
      a {
        font-size: 15px;

        &.small {
          font-size: 15px !important;
        }

        &.tiny {
          font-size: 13px !important;
        }
      }
    }
  }
}

</style>
