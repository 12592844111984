import { UserLevelNames, OrganizationLevelNames, OrganizationIcons, OrganizationColors, OrganizationHierarchy, getUserIcon, getUserColor } from '@stellacontrol/model'
import { formatDate } from '@stellacontrol/utilities'

/**
 * Organizations list columns.
 * Sorting is disabled as this is a hierarchical view.
 */
const organizationColumns = [
  { name: 'hierarchy', showOnSmallScreen: true, label: 'Organization', field: 'name', align: 'left', style: 'width: 150px' },
  { name: 'profile', showOnSmallScreen: false, label: 'Profile', field: 'profile', align: 'left', format: profile => profile ? profile.fullName : '', style: 'width: 150px' },
  { name: 'administrator', showOnSmallScreen: false, label: 'Administrator', field: 'administrator', align: 'left', format: administrator => administrator ? administrator.name : '', style: 'width: 120px' },
  { name: 'devices', showOnSmallScreen: false, label: 'Devices', field: 'devices', align: 'right' },
  { name: 'created', showOnSmallScreen: false, label: 'Created On', field: 'createdAt', sortable: true, align: 'right', format: value => formatDate(value), desktopOnly: true },
  { name: 'updated', showOnSmallScreen: false, label: 'Updated On', field: 'updatedAt', align: 'right', format: value => formatDate(value), desktopOnly: true },
  { name: 'actions', showOnSmallScreen: true, label: 'Actions', align: 'right', style: 'width: 80px' }
]

/**
 * Child organizations list columns.
 */
const childOrganizationColumns = [
  { name: 'icon', label: 'Level', field: 'level', format: (value, item) => ({ icon: OrganizationIcons[value], color: item.isEnabled ? OrganizationColors[value] : 'grey-5' }), sortable: true, align: 'left' },
  { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left' },
  { name: 'profile', label: 'Profile', field: 'profile', sortable: true, align: 'left', format: profile => profile ? profile.fullName : '' },
  { name: 'level', label: 'Level', field: 'level', sortable: true, align: 'left', format: level => OrganizationLevelNames[level], desktopOnly: true },
  { name: 'parent', label: 'Parent Organization', field: 'parentOrganization', sortable: true, align: 'left', format: parent => parent.name },
  { name: 'isEnabled', label: 'Status', field: 'isEnabled', align: 'left', format: isEnabled => `${isEnabled ? 'Active' : 'Inactive'}`, style: 'width: 150px' },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

/**
 * Organization's users list columns.
 */
const usersInOrganizationColumns = [
  { name: 'icon', label: 'Level', field: 'level', format: (_, item) => ({ icon: getUserIcon(item), color: getUserColor(item), sortable: true, align: 'left' }) },
  { name: 'fullName', label: 'User', field: 'fullName', sortable: true, align: 'left', required: true },
  { name: 'email', label: 'Login', field: 'name', sortable: true, align: 'left', required: true },
  { name: 'level', label: 'Level', field: 'level', sortable: true, align: 'left', format: (level, item) => item.isPrimaryAdministrator ? 'Primary Administrator' : UserLevelNames[level] },
  { name: 'phone', label: 'Phone', field: 'phone', sortable: true, align: 'left', desktopOnly: true },
  { name: 'isEnabled', label: 'Status', field: 'isEnabled', align: 'left', format: isEnabled => `${isEnabled ? 'Active' : 'Inactive'}` },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

/**
 * Organization's own devices
 */
const organizationDevicesColumns = [
  { name: 'serialNumber', label: 'Serial Number', field: 'serialNumber', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'acronym', label: 'Type', field: 'acronym', sortable: true, align: 'left', style: 'width: 100px' },
  { name: 'model', label: 'Model', field: 'model', sortable: true, align: 'left' },
  { name: 'firmwareVersion', label: 'Firmware', field: 'firmwareVersion', sortable: true, align: 'left' },
  { name: 'place', label: 'Place', field: 'place', sortable: true, align: 'left', format: place => place ? place.name : '-' },
  { name: 'location', label: 'Device Location', field: 'location', sortable: true, align: 'left', format: (value, row) => row.customLocation || row.location || '-' }
]

export function createState () {
  return {
    /**
     * Organizations available to the currently logged in organization
     */
    items: null,
    /**
     * Current organization
     */
    currentOrganization: null,
    /**
     * Organizations currently collapsed in the hierarchy view
     */
    collapsedOrganizations: [],
    /**
     * All organizations
     */
    allItems: [],
    /**
     * Organization list columns
     */
    organizationColumns,
    /**
     * Users-in-organization columns
     */
    usersInOrganizationColumns,
    /**
     * Child organization list columns
     */
    childOrganizationColumns,
    /**
     * Organization's own devices columns
     */
    organizationDevicesColumns,
    /**
     * Last created or updated organization
     */
    lastUpdated: null
  }
}

export const state = createState()

export const getters = {
  /**
   * Organizations available to the currently logged in organization
   */
  organizations: state =>
    state.items || [],

  /**
   * Dictionary of organizations, by identifier
   */
  organizationsDictionary: (state, getters) =>
    getters.organizations.reduce((all, o) => ({ ...all, [o.id]: o }), {}),

  /**
   * All users belonging to the current organization and its child organizations
   */
  allUsers: state => {
    const users = (state.items || []).reduce((all, o) => ([...all, ...o.users || []]), [])
    return users
  },

  /**
   * Dictionary of all users, by identifier
   */
  allUsersDictionary: (state, getters) =>
    getters.allUsers.reduce((all, u) => ({ ...all, [u.id]: u }), {}),

  /**
    * Organizations hierarchy available to the currently logged in organization
    */
  organizationHierarchy: state => {
    const hierarchy = OrganizationHierarchy.fromOrganizations(state.items || [], state.currentOrganization, false)
    return hierarchy
  },

  /**
   * Full organizations hierarchy including parents, available to the currently logged in organization
   */
  fullHierarchy: state => {
    const hierarchy = OrganizationHierarchy.fromOrganizations(state.items || [], state.currentOrganization, true)
    return hierarchy
  },

  /**
   * All organizations
   */
  allOrganizations: state =>
    state.allItems || [],

  /**
   * Indicates that there are organizations available
   */
  hasOrganizations: state =>
    state.items && state.items.length > 0,

  /**
   * Finds an organization
   * @param id Organization identifier
   */
  getOrganization: state =>
    id => (state.items || []).find(o => o.id === id),

  /**
   * Organization list columns
   */
  organizationColumns: state =>
    state.organizationColumns,

  /**
   * Users-in-Organizatio list columns
   */
  usersInOrganizationColumns: state =>
    state.usersInOrganizationColumns,

  /**
   * Child organization list columns
   */
  childOrganizationColumns: state =>
    state.childOrganizationColumns,

  /**
   * The statistics of organizations' devices etc.
   */
  organizationStatistics: (state, getters) => {
    const statistics = {}
    const { organizations, devices } = getters
    if (organizations && devices) {
      for (const { id } of organizations) {
        statistics[id] = { ownDeviceCount: 0, childDeviceCount: 0 }
      }
      for (const device of devices) {
        if (device.ownerId) {
          if (!statistics[device.ownerId]) {
            statistics[device.ownerId] = { ownDeviceCount: 0, childDeviceCount: 0 }
          }
          statistics[device.ownerId].ownDeviceCount++
        }
      }
    }
    return statistics
  }
}
