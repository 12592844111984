import { Log, wait } from '@stellacontrol/utilities'
import { Notification } from '../../services'

/**
 * Client actions
 */
export const actions = {
  /**
 * Sets the application details, first step in bootstrapping the application
 */
  setApplication ({ commit }, pkg = {}) {
    commit('setApplication', pkg)
  },

  /**
   * Initialize the application
   * @param context Application context
   */
  async initializeApplication ({ commit, dispatch }) {
    // Start the ticker
    window.setInterval(() => {
      commit('tick')
    }, 1000)

    // Set up global click event, useful for dismissing popups etc.
    document.addEventListener('click', (event) => {
      dispatch('click', { event })
    })

    // Unload handler
    window.addEventListener('beforeunload', async () => {
      await dispatch('unloadApplication')
    })
  },

  /**
   * Signal failed initialization of the application
   */
  async initializationFailed ({ commit }) {
    commit('initializationFailed')
  },

  /**
   * Initializes the UI router
   * @param router UI router
   */
  initializeRouter ({ dispatch, getters }, { router }) {
    // Make sure the application is initialized,
    // before we allow any navigation
    router.beforeEach(async (to, from) => {
      // If application not initialized yet, do it now
      const { isInitialized, initializationFailed } = getters
      // Do not try it though, if tried once and failed
      const shouldInitialize = !(isInitialized || initializationFailed)

      if (shouldInitialize) {
        // We expect the client configuration to be found on
        // window.stellacontrol variable
        const { scPackage, scConfiguration } = window
        try {
          await dispatch('initializeApplication', { pkg: scPackage, ...scConfiguration })

        } catch (error) {
          // If application initialization has failed, stop trying and show error page
          Log.error('Initialization failed!')
          Log.exception(error)
          await dispatch('initializationFailed')
          await dispatch('gotoError')
        }
      }

      // If initialization failed, stop navigation
      if (initializationFailed) {
        Log.error('Application aborted.')
        if (from.name === 'error' || to.name !== 'error') {
          return false
        }
      }
    })
  },

  /**
   * Triggered when the application is unloaded
   */
  async unloadApplication ({ commit }) {
    commit('unloadApplication')
  },

  /**
   * Waits for a while
   * @param {Number} interval Wait interval in milliseconds
   */
  async wait (_, { interval = 1000 } = {}) {
    await wait(interval)
  },

  /**
   * Report an unhandled error
   * @param {Error} error Exception
   * @param {String} message User-friendly message to show
   */
  async fail ({ commit, dispatch }, { error, message }) {
    commit('navigationCancelled')
    commit('error', { error, message })
    await dispatch('gotoError')
  },

  /**
   * Recovers after an unhandled error
   */
  async recover ({ commit, dispatch }) {
    // Hide any pending progress notification
    await dispatch('done')
    // Clear the error
    commit('navigationCancelled')
    commit('error')
  },

  /**
   * Shows popup with network error
   * @param {Error} error Error details
   */
  async showNetworkErrorPopup ({ dispatch }, { error }) {
    await dispatch('recover')
    await dispatch('showDialog', {
      dialog: 'network-error-popup',
      data: { error }
    })
  },

  /**
   * Indicates start of a long-lasting activity,
   * displays progress notification if message is specified
   * @param {String} action Action with which we're busy, unique code
   * @param {String} message User-friendly message describing the activity
   * @param {String} details More detailed description of the activity
   * @param {Object} data Data associated with the activity
   * @param {Boolean} silent If true, no notification is shown.
   * @param {Number} timeout If greater than zero, notification will disappear automatically, otherwise
   * it will stay until `done` is called
   * Can help reduce code complexity and unnecessary `if` statements in certain cases.
   */
  busy ({ commit, getters, state }, { action, message, details, data, silent, timeout = 0 } = {}) {
    if (silent) () => { }

    // Close any pending busy notifications
    if (getters.isBusy) {
      const { hideNotification } = state.busy
      if (hideNotification) {
        hideNotification()
      }
      commit('done')
    }
    // Show new busy notification
    const hideNotification = message ? Notification.progress({ message, details, timeout }) : () => { }
    commit('busy', { action, message, details, data, hideNotification })
    return hideNotification
  },

  /**
   * Indicates start of a data loading action for a view,
   * displays progress notification if message is specified
   * @param {String} message User-friendly message describing the activity
   * @param {String} details More detailed description of the activity
   * @param {Object} data Data associated with the activity
   * @param {Boolean} silent If true, no notification is shown.
   * Can help reduce code complexity and unnecessary `if` statements in certain cases.
   */
  loading ({ commit, getters, state }, { message, details, data, silent } = {}) {
    if (silent) return () => { }

    // Close any pending busy notifications
    if (getters.isBusy) {
      const { hideNotification } = state.busy
      if (hideNotification) {
        hideNotification()
      }
      commit('done')
    }
    // Show new loading notification
    const hideNotification = message ? Notification.progress({ message, details }) : () => { }
    commit('loading', { message, details, data, hideNotification })
    return hideNotification
  },

  /**
   * Indicates the end of a running activity, started with `busy` action.
   * Displays success or failure notification if message or error is specified.
   * @param {String} message User-friendly message to report with end of the activity
   * @param {String} warning Warning to report with end of the activity
   * @param {String} error Error to report with end of the activity
   * @param {String} details More detailed description of the activity
   * @param {Object} data Data associated with end of the activity
   * @param {Boolean} silent If true, no notification is shown.
   * Can help reduce code complexity and unnecessary `if` statements in certain cases.
   */
  async done ({ commit, state }, { message, warning, error, details, data, silent } = {}) {
    // Hide pending progress notification...
    const { startedAt, hideNotification } = state.busy
    const duration = startedAt ? (new Date()) - startedAt : 0
    if (hideNotification) {
      // ...but wait a bit if activity was quickly finished,
      // otherwise we get those nasty blinking notifications
      if (duration < 1000 && !silent) {
        await wait(1000 - duration)
      }
      hideNotification()
    }

    // Display finish notification after a brief while,
    // to give time to hide the progress notification
    if (!silent) {
      if (message || warning || error) {
        await wait(500)
        if (message) {
          Notification.success({ message, details })
        } else if (warning) {
          Notification.warning({ message: warning, details })
        } else if (error) {
          Notification.error({ message: error, details })
        }
      }
    }

    commit('done', { message, warning, error, details, data, duration })
  },

  /**
   * Redirects to another URL with full page reload
   * @param {String} message User-friendly message describing the redirection
   * @param {Boolean} instant If true, redirection is instant, without the intermediate Redirecting view
   */
  async redirectToUrl ({ commit, dispatch }, { message, url, instant } = {}) {
    commit('redirectToUrl', { message, url })
    await dispatch('gotoRoute', { name: 'redirecting' })

    window.setTimeout(() => {
      window.location = url
    }, instant ? 0 : 1000)
  },

  /**
   * Opens the specified URL in new window/tab
   * @params {String} url URL to open in the new window/tab
   */
  async openUrl (_, { url } = {}) {
    window.open(url, '_blank').focus()
  },

  /**
   * Redirects to home page with full page reload
   * @param {String} message User-friendly message describing the redirection
   * @param {Boolean} instant If true, redirection is instant, without the intermediate Redirecting view
   */
  async redirectToHome ({ dispatch }, { message, instant } = {}) {
    await dispatch('gotoHome')
    await dispatch('reload', { message, instant })
  },

  /**
   * Reloads the page
   * @param {String} message User-friendly message describing the reload
   * @param {String} url Optional url to reload. If not specified, current page will be reloaded
   * @param {Boolean} instant If true, reload is instant, without the intermediate Reloading view
   */
  async reload ({ commit, dispatch }, { message, instant, url: customUrl } = {}) {
    if (window) {
      const url = customUrl || window.location.toString()

      if (!instant) {
        commit('reload', { message })
        await dispatch('gotoRoute', { name: 'reloading' })
      }

      window.setTimeout(() => {
        window.location = url
        window.location.reload()
      }, instant ? 0 : 1000)
    }
  },

  /**
   * Reset action which reverts the underlying store to its original state.
   * Implement in modules to make sure that they're cleared properly when requested,
   * for example when user logs out of the application
   */
  reset ({ commit }) {
    commit('reset')
  }
}
