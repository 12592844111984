<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import OrganizationGeneral from './organization-general.vue'
import OrganizationPermissions from './organization-permissions.vue'
import OrganizationUsers from './organization-users.vue'
import ChildOrganizations from './organization-child-organizations.vue'
import { Secure, Permissions } from '../../components'
import { resolveOrganization } from './organization.resolve'

const name = 'organization'

/**
 * Organization editor
 */
export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-organization-general': OrganizationGeneral,
    'sc-organization-permissions': OrganizationPermissions,
    'sc-organization-users': OrganizationUsers,
    'sc-child-organizations': ChildOrganizations
  },

  data () {
    return {
      name,
      permissions: null
    }
  },

  computed: {
    ...mapGetters([
      'environment',
      'features',
      'loggedInAsMaster',
      'previousRouteName',
      'organizations',
      'isSmallScreen'
    ]),

    ...mapState({
      // Data of the created or updated organization
      lastUpdated: state => state.organizations.lastUpdated
    }),

    forms () {
      return [
        'general'
      ]
    },

    // View title
    title () {
      const { parentOrganization, isNew, profile } = this.data
      const prefix = profile ? profile.fullName : 'Organization'
      if (isNew) {
        if (parentOrganization && !this.isCurrentOrganizationParent) {
          return `New ${prefix} of ${parentOrganization.name}`
        } else {
          return `New ${prefix}`
        }
      } else {
        return `${this.data.name}, ${prefix}`
      }
    },

    // True, if parent organization is the currently logged in one
    isCurrentOrganizationParent () {
      const { parentOrganization } = this.data
      return parentOrganization && parentOrganization.id === this.currentOrganization.id
    },

    // Identifier of parent organization
    parentOrganizationId () {
      return this.data.parentOrganizationId
    },

    // True, if organization has been locked by creator,
    // so it can only be edited by reseller.
    // Used to protect large customers from editing their customers and divisions hierarchy,
    // managed instead by their reseller.
    isLocked () {
      return this.data.isLocked &&
        !this.currentOrganization.isSuperOrganization &&
        !this.currentOrganization.isResellerOrganization
    },

    // Indicates whether we can edit edit permissions of the organization.
    canEditPermissions () {
      return this.canUse('child-organizations') && !this.isLocked
    },

    // Indicates whether we can edit child organizations.
    // Not available when editing a new organization.
    // Only available when:
    // * edited organization can have child organizations and
    // * editor is a super and reseller organization
    // * editor is granted with a child-organizations permission.
    canEditChildOrganizations () {
      return !this.data.isNew && this.permissions.canUse('child-organizations') && !this.isLocked
    },

    // Indicates whether we can edit users in child organizations.
    // Not available when editing a new organization.
    // Current organization requires a child-users permission.
    canEditUsers () {
      return !this.data.isNew && this.canUse('child-users') && !this.isLocked
    },

    // Indicates whether we can log into the edited organization
    canLoginAs () {
      const { data, loggedInAsMaster, currentOrganization } = this
      const { administrator, isNew } = data
      return !isNew && // Must be existing organization,
        data.id !== currentOrganization.id && // Cannot log on to self,
        this.canUse('login-to-child-organizations') && // Must have permission to log in to child organizations,
        !loggedInAsMaster && // Must not already be logged-in-as,
        administrator && administrator.isEnabled // Administrator of the organization must be in order
    },

    // Returns true if came here from organization view
    fromOrganizationRoute () {
      return (this.fromRoute || {}).name === 'organization'
    },

    // Returns true if came here from installations view
    fromInstallationsRoute () {
      return (this.fromRoute || {}).name === 'installations'
    },

    // Returns identifier of organization where we came from
    fromOrganizationId () {
      return (this.fromRoute || {}).params.id
    },

    // View breadcrumbs.
    // If editing child organization, breadcrumbs must allow navigation
    // back to the owner organization
    breadcrumbs () {
      const { data: organization, organizations, fromInstallationsRoute, fromOrganizationRoute, fromOrganizationId, getViewTitle } = this

      // When came from /buildings page, breadcrumbs should allow returning there
      if (fromInstallationsRoute) {
        const breadcrumbs = [
          {
            name: 'home',
            title: getViewTitle('home')
          },
          {
            name: 'installations',
            title: getViewTitle('installations')
          },
          {
            name: 'organization',
            title: organization.name
          }
        ]
        return breadcrumbs
      }

      // If came from /organization route into its child organization,
      // breadcrumbs must allow returning to the parent organization
      if (fromOrganizationRoute && fromOrganizationId && organization.parentOrganizationId === fromOrganizationId) {
        const parent = organizations.find(o => o.id === fromOrganizationId)

        const breadcrumbs = [
          {
            name: 'home',
            title: getViewTitle('home')
          },
          {
            name: 'organizations',
            title: getViewTitle('organizations')
          },
          parent
            ? {
              name: 'organization',
              title: parent.name,
              route: 'organization',
              query: { tab: 'child-organizations' },
              params: { id: parent.id }
            }
            : undefined,
          {
            name: 'organization',
            title: organization.name
          }
        ]
        return breadcrumbs
      }
    }
  },

  methods: {
    ...mapActions([
      'saveOrganization',
      'editOrganization',
      'editOrganizations',
      'goBack',
      'gotoRoute',
      'gotoLogin',
      'createUser',
      'createChildOrganization',
      'createSampleOrganizationData',
      'loginToOrganization'
    ]),

    // Adds user to organization
    async addUserToOrganization () {
      if (await this.save(false)) {
        await this.createUser({ organization: this.data })
      }
    },

    // Adds child organization to organization
    async addChildOrganization () {
      if (await this.save(false)) {
        await this.createChildOrganization({ organization: this.data })
      }
    },

    // Saves the organization and navigates back to where the user came from
    async save () {
      if (this.isLocked) {
        return
      }

      if (await this.validate()) {
        // Make sure the permissions have been stored in the organization
        this.permissions.apply()
        // Save
        const { isNew, email, createSampleData } = this.data
        const createAdministratorAccount = Boolean(isNew && email)
        await this.saveOrganization({ organization: this.data, createAdministratorAccount })

        if (isNew && this.lastUpdated) {
          if (createSampleData) {
            await this.createSampleOrganizationData({ organization: this.lastUpdated, data: createSampleData })
          }
          await this.editOrganization({ organization: this.lastUpdated })
        }
        return true

      } else {
        this.selectTab('general')
      }
    },

    async cancel () {
      const { parentOrganizationId, organizations, fromOrganizationRoute, fromOrganizationId } = this
      if (fromOrganizationRoute && fromOrganizationId && fromOrganizationId === parentOrganizationId) {
        const parent = organizations.find(o => o.id === fromOrganizationId)
        if (parent) {
          await this.editOrganization({ organization: parent })
          return
        }
      }
      await this.editOrganizations()
    },

    // Resets the view to original shape,
    // as when another organization is loaded
    async reset () {
      // Switch to default tab if one specified in URL has become invisible
      this.ensureTab()
      // Instantiate the permissions controller and populate with features
      // and permissions of the currently edited principal
      if (this.currentUser) {
        this.permissions = new Permissions(this.features, this.data, this.environment, this.currentUser, this.guardian)
      } else {
        this.gotoLogin()
      }
    },

    // Logs into the organization
    loginAs () {
      return this.loginToOrganization({
        organization: this.data,
        confirm: true
      })
    }
  },

  created () {
    this.reset()
    if (this.data.isNew) {
      this.selectTab('general')
    }
  },

  // Reload data on navigation to another organization
  async beforeRouteUpdate (to, from) {
    if (from.params.id !== to.params.id) {
      if (await resolveOrganization({ from, to, id: to.params.id })) {
        this.reset()
      }
    }
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" v-if="isLoggedIn" :breadcrumbs="breadcrumbs">

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <span class="text-body1 q-mx-sm text-white">
        {{ title }}
      </span>

      <q-space>
      </q-space>

      <div class="row items-center no-wrap q-gutter-sm">
        <q-btn outline style="color: white" icon="arrow_back" @click="gotoRoute({ name: 'organizations' })" />
      </div>
    </teleport>

    <template #toolbar>
      <div class="row q-gutter-xs">
        <q-btn label="Log in to the organization" v-if="canLoginAs" unelevated no-caps
          @click="loginAs()"></q-btn>
        <q-btn label="Save" v-if="!isLocked" class="primary q-ml-lg" unelevated
          @click="save()"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <sc-tabs :model-value="viewTab" @update:model-value="tab => selectTab(tab)" :tabs="viewTabs">
      <q-tab name="general" icon="create" label="Details" :ripple="false"></q-tab>
      <q-tab v-if="canEditPermissions" name="permissions" icon="security" label="Permissions"
        :ripple="false"></q-tab>
      <q-tab v-if="canEditUsers" name="users" icon="people" label="Users" :ripple="false"></q-tab>
      <q-tab v-if="canEditChildOrganizations" name="child-organizations" icon="home_work"
        label="Child Organizations" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels :model-value="viewTab" :tabs="viewTabs">
      <q-tab-panel name="general">
        <sc-organization-general :data="data" :permissions="permissions" ref="general" />
      </q-tab-panel>
      <q-tab-panel name="permissions" v-if="canEditPermissions">
        <sc-organization-permissions :data="data" :permissions="permissions" ref="permissions" />
      </q-tab-panel>
      <q-tab-panel name="users" v-if="canEditUsers">
        <sc-organization-users :data="data" ref="users"
          @addUserToOrganization="addUserToOrganization()" />
      </q-tab-panel>
      <q-tab-panel name="child-organizations" v-if="canEditChildOrganizations">
        <sc-child-organizations :data="data" ref="child-organizations"
          @addChildOrganization="addChildOrganization()" />
      </q-tab-panel>
    </sc-tab-panels>
  </sc-view>
</template>

<style lang='scss' scoped></style>
