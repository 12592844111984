<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { createMetaMixin } from 'quasar'
import { fill } from '@stellacontrol/utilities'
import { isRoute, Viewport } from '@stellacontrol/client-utilities'
import ApplicationViewContainer from './components/application-view-container.vue'
import ApplicationSidebar from './components/application-sidebar.vue'
import ApplicationTopbar from './components/application-topbar.vue'
import ApplicationUpdateIndicator from './components/application-update-indicator.vue'
import AdBlockDetector from './components/adblock-detector.vue'

export default {
  name: 'StellaControlApplication',

  mixins: [
    createMetaMixin(function () {
      const { reCaptcha, isRecaptchaRequired } = this
      return {
        // Apply custom UI theme from client configuration
        link: {
          defaultTheme: { rel: 'stylesheet', href: 'themes/default.css' },
          customTheme: this.branding.theme ? { rel: 'stylesheet', href: this.branding.theme } : undefined
        },

        // Application title
        title: this.applicationTitle,

        // Scripts
        script: {
          // Add Google reCaptcha library on login page
          ...(isRecaptchaRequired
            ? { reCaptcha: { src: `${reCaptcha.script}?render=${reCaptcha.key}`, type: 'text/javascript' } }
            : {}
          )
        },

        // Output current route name as body attribute
        bodyAttr: {
          'route': this.currentRouteName || 'home',
          ...(this.showReCaptchaBadge ? { 'show-recaptcha': 'yes' } : {})
        }
      }
    })
  ],

  components: {
    'sc-application-view-container': ApplicationViewContainer,
    'sc-application-sidebar': ApplicationSidebar,
    'sc-application-topbar': ApplicationTopbar,
    'sc-application-update-indicator': ApplicationUpdateIndicator,
    'sc-adblock-detector': AdBlockDetector
  },

  computed: {
    ...mapState({
      // Indicates whether the new announcements popup is now visible
      isAnnouncementPopupVisible: state => state.announcements.isAnnouncementPopupVisible,
      // New announcements to show
      announcements: state => state.announcements.newAnnouncements
    }),

    ...mapGetters([
      'application',
      'branding',
      'configuration',
      'currentRoute',
      'currentRouteData',
      'currentRouteName',
      'environment',
      'isDevelopmentEnvironment',
      'isError',
      'isInitialized',
      'isLoggedIn',
      'isLoggingOut',
      'isSmallScreen',
      'isProductionEnvironment',
      'isRedirecting',
      'isReloading',
      'termsAndConditionsAccepted'
    ]),

    applicationTitle () {
      const { isInitialized, application, configuration, currentRoute = {} } = this
      if (isInitialized) {
        const { meta: { view } } = currentRoute
        // The title comes from route data or is hard-coded on the view definition
        const { replaceTitle } = this.currentRouteData || {}
        // The title can refer to variables from application configuration, such as company name etc.
        const viewTitle = fill(this.currentRouteData?.title || view?.title || '', configuration)
        // Application title is either prepended by application name, or replaced entirely by the view title
        const applicationTitle = replaceTitle
          ? viewTitle
          : `${application.name}${viewTitle ? ' | ' : ''}${viewTitle}`

        return applicationTitle

      } else {
        return `${application.name} is loading ...`
      }
    },

    // Google reCaptcha configuration
    reCaptcha () {
      return this.configuration ? this.configuration.security.login.reCaptcha : {}
    },

    // Returns true if reCaptcha is required on the current page
    isRecaptchaRequired () {
      const { reCaptcha, currentRouteName } = this
      return reCaptcha.enabled && (currentRouteName === 'login' || currentRouteName === 'reset-password')
    },

    // Returns true if reCaptcha badge should be visible to the user on login page
    showReCaptchaBadge () {
      const { currentRouteName, reCaptcha } = this
      return currentRouteName === 'login' && reCaptcha.showBadge && !Viewport.mobileScreenDetected
    },

    // Indicates that we're on home page now
    isHome () {
      return isRoute(this.currentRoute, 'home')
    },

    // Unacknowledged announcements
    newAnnouncements () {
      return this.announcements?.filter(a => !a.isAcknowledged) || []
    },

    // Visibility of indicator of unacknowledged announcements
    showNewAnnouncements () {
      return this.isInitialized &&
        this.isLoggedIn &&
        !this.isHome &&
        this.currentRouteName !== 'announcements' &&
        this.newAnnouncements.length > 0
    }
  },

  methods: {
    ...mapActions([
      'collapseSidebar'
    ])
  },

  watch: {
    // When user has logged in but hasn't approved terms and conditions yet,
    // hide the sidebar for better readability
    isLoggedIn () {
      if (this.isLoggedIn && !this.termsAndConditionsAccepted) {
        this.collapseSidebar({ persistent: false })
      }
    }
  },

  /**
   * Triggered when applet has been inserted into the page
   * Notifies the host application that the applet is ready.
   * In response, Host application must call `applet.initialize`
   * and provide configuration data, logged user, devices etc.
   */
  created () {
    const event = new CustomEvent('stellacontrol-application-created', {
      detail: { application: this }
    })
    window.dispatchEvent(event)
  }
}

</script>

<template>
  <q-layout class="stellacontrol" v-if="!isLoggingOut" v-cloak>

    <sc-application-topbar v-if="isSmallScreen"></sc-application-topbar>
    <sc-application-sidebar v-else></sc-application-sidebar>

    <sc-application-view-container></sc-application-view-container>

    <div class="application-update-indicator">
      <sc-application-update-indicator></sc-application-update-indicator>
    </div>

    <div class="announcement-indicator shake" v-if="showNewAnnouncements">
      <sc-announcement-indicator></sc-announcement-indicator>
    </div>

    <div id="announcement-popup" class="announcement-popup" v-if="isAnnouncementPopupVisible">
      <sc-announcement-popup></sc-announcement-popup>
    </div>
  </q-layout>

  <sc-device-configuration-dialog></sc-device-configuration-dialog>
  <sc-device-history-dialog></sc-device-history-dialog>
  <sc-help-popup></sc-help-popup>
  <sc-network-error-popup></sc-network-error-popup>
  <sc-adblock-detector></sc-adblock-detector>
</template>

<style scoped lang="scss">
.stellacontrol {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.application-update-indicator {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999999;

  .button-update {
    border-radius: 0 !important;
  }
}

.announcement-indicator {
  position: fixed;
  top: 12px;
  right: 12px;
  z-index: 99999999;
}

.announcement-popup {
  position: fixed;
  top: 12px;
  right: 10px;
  z-index: 99999999;
  width: 420px;
  max-height: 800px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: solid #0000001f 1px;
  background-color: #202020;
  box-shadow: 0 0 4px #404040;
  padding: 16px 8px 16px 4px;
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1365px) {
  .stellacontrol {
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .application-update-indicator {
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
