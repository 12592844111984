/**
 * Audited actions
 */
export const AuditAction = {
  // Generic actions
  Access: 'access',
  Create: 'create',
  Update: 'update',
  Modify: 'modify',
  Edit: 'edit',
  Delete: 'delete',
  RevokePermissions: 'revoke-permissions',
  GrantPermissions: 'grant-permissions',
  SetPreferences: 'set-preferences',
  DeletePreferences: 'delete-preferences',

  // Device-related actions
  LinkDevice: 'link-device',
  UnlinkDevice: 'unlink-device',
  AddToPlace: 'add-device-to-place',
  RemoveFromPlace: 'remove-device-from-place',
  UpdateFirmware: 'update-firmware',
  SellDevice: 'sell-device',
  ResetDevice: 'reset-device',
  SetDeviceVersion: 'set-device-version',
  DecommissionDevice: 'decommission-device',
  SwapDevice: 'swap-device',
  ChangeDeviceLocation: 'change-device-location',
  ConfigureDevice: 'configure-device',

  // User-related actions
  Login: 'login',
  LoginFailed: 'login-failed',
  ResetPassword: 'reset-password',
  ChangePassword: 'change-password',
  ChangeLevel: 'change-level',
  InviteUser: 'invite-user',
  ActivateUser: 'activate-user',
  AcceptTermsAndConditions: 'accept-tc',

  // Organization-related actions
  ChangeOrganizationProfile: 'change-organization-profile',

  // Wallet-related actions
  CreditTokens: 'token-credit',
  DebitTokens: 'token-debit',
  SellTokens: 'token-sell',
  BuyTokens: 'token-buy',
  AssignPremiumService: 'assign-premium-service',
  ClearPremiumService: 'clear-premium-service',
  SubscribePremiumService: 'subscribe-premium-service',
  UnsubscribePremiumService: 'unsubscribe-premium-service',

  // Alert-related actions
  SendNotification: 'send-notification',
  SendNotificationFailed: 'send-notification-failed',

  // UI actions
  PageViewed: 'page-viewed',

  // Other actions
  SendMail: 'send-mail'
}

export const AuditActionDescription = {
  [AuditAction.Access]: 'Access',
  [AuditAction.Create]: 'Create',
  [AuditAction.Update]: 'Update',
  [AuditAction.Delete]: 'Delete',
  [AuditAction.GrantPermissions]: 'Grant permissions',
  [AuditAction.SetPreferences]: 'Change preferences',
  [AuditAction.DeletePreferences]: 'Delete preferences',
  [AuditAction.LinkDevice]: 'Share device',
  [AuditAction.UnlinkDevice]: 'Unshare device',
  [AuditAction.AddToPlace]: 'Add device to place',
  [AuditAction.RemoveFromPlace]: 'Remove device from place',
  [AuditAction.UpdateFirmware]: 'Update device firmware',
  [AuditAction.SellDevice]: 'Sell device',
  [AuditAction.ResetDevice]: 'Reset device',
  [AuditAction.DecommissionDevice]: 'Decommission device',
  [AuditAction.SwapDevice]: 'Swap device',
  [AuditAction.ChangeDeviceLocation]: 'Change device location',
  [AuditAction.ConfigureDevice]: 'Configure device',
  [AuditAction.Login]: 'Login',
  [AuditAction.ResetPassword]: 'Reset password',
  [AuditAction.ChangePassword]: 'Change password',
  [AuditAction.ChangeLevel]: 'Change level',
  [AuditAction.InviteUser]: 'Invite user',
  [AuditAction.ActivateUser]: 'Activate user',
  [AuditAction.AcceptTermsAndConditions]: 'Accept Terms and Conditions',
  [AuditAction.ChangeOrganizationProfile]: 'Change organization profile',
  [AuditAction.CreditTokens]: 'Credit tokens',
  [AuditAction.DebitTokens]: 'Debit tokens',
  [AuditAction.SellTokens]: 'Sell tokens',
  [AuditAction.BuyTokens]: 'Buy tokens',
  [AuditAction.AssignPremiumService]: 'Assign Premium Service',
  [AuditAction.ClearPremiumService]: 'Clear Premium Service',
  [AuditAction.SubscribePremiumService]: 'Start Premium Service',
  [AuditAction.UnsubscribePremiumService]: 'End Premium Service',
  [AuditAction.SendNotification]: 'Send notification',
  [AuditAction.SendNotificationFailed]: 'Notification failed',
  [AuditAction.SendMail]: 'Send mail'
}
