<script>
import { mapActions, mapGetters } from 'vuex'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const name = 'administration'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name
    }
  },

  computed: {
    ...mapGetters([
      'getRouteByView',
      'getViewPermissions',
      'isSmallScreen'
    ]),

    items: function () {
      const { currentUser, canUse, isSuperOrganization, isSmallScreen } = this
      const items = [
        {
          icon: 'person',
          view: 'my-account',
          title: 'My Account',
          details: 'My personal data, recent history etc.'
        },
        {
          icon: 'settings',
          view: 'preferences',
          title: 'Preferences',
          details: 'Application settings and user preferences',
          isVisible: !currentUser.isGuestUser
        },
        {
          icon: 'people_alt',
          view: 'users',
          title: 'Users',
          details: 'User accounts in my organization and their permissions',
          isVisible: !isSmallScreen
        },
        {
          icon: 'business',
          view: 'organizations',
          title: `${isSuperOrganization ? '' : 'My'} Organizations`,
          details: `${isSuperOrganization ? 'All' : 'My'} customers, their permissions and other details`
        },
        {
          icon: 'home_work',
          view: 'organization-profiles',
          title: 'Organization Profiles',
          details: 'Permission templates for customers',
          permissions: ['organization-profiles'],
          isVisible: !isSmallScreen
        },
        {
          icon: 'memory',
          view: 'device-updates',
          title: canUse('can-manage-firmware') ? 'Firmware Repository' : 'Device Updates',
          details: canUse('can-manage-firmware') ? 'Firmware repository and device updates' : 'Device firmware updates',
          permissions: ['device-management-firmware'],
          isVisible: !isSmallScreen
        },
        {
          icon: 'notifications',
          view: 'announcements',
          title: 'Customer Announcements',
          details: 'Messages about products, platform updates, planned outages etc.',
          permissions: ['announcements'],
          isVisible: !isSmallScreen
        },
        {
          icon: 'location_on',
          view: 'floor-plans',
          title: 'StellaPlanner',
          details: 'Legacy version of the floor planner, soon to be retired',
          permissions: ['floor-plans'],
          isVisible: !isSmallScreen

        }
      ]

      return items.filter(item =>
        item.isVisible !== false &&
        (item.permissions ? this.canUseAll(item.permissions) : this.canSeeView(item.view)))
    },

    route () {
      return view => {
        const route = this.getRouteByView(view)
        if (!route) {
          throw new Error('Route not defined for view', view)
        }
        return route
      }
    }
  },

  methods: {
    ...mapActions([
      'gotoRoute'
    ]),

    open (view) {
      const { name } = this.route(view) || {}
      if (name) {
        this.gotoRoute({ name })
      }
    }
  }
}
</script>

<template>
  <sc-view :name="name">

    <div class="items">
      <div class="item"
        v-for="item in items"
        :key="item.title"
        @click="open(item.view)">

        <div class="title q-mb-md">
          <div class="icon">
            <q-icon :name="item.icon" size="28px" />
          </div>

          <label class="q-ml-sm">
            <router-link @click.stop :to="{ name: route(item.view).name }">
              {{item.title}}
            </router-link>
          </label>
        </div>

        <div class="details q-pl-sm">
          <label class="text-subtitle1">
            {{item.details}}
          </label>
        </div>
      </div>
    </div>

  </sc-view>
</template>

<style lang='scss' scoped>


:root {
  --screen-width-full-hd: 1920px;
  --screen-width-below-full-hd: 1919px;
  --screen-width-hd: 1366px;
  --screen-width-below-hd: 1365px;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 48px;
  overflow: auto;

  .item {
    height: 140px;
    flex-basis: 310px;
    margin-right: 16px;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 2px;
    background-color: #eceff1;
    transition: all 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #d9dbe7;
    }

    label {
      user-select: none;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #313131;
      }
    }

    .title {
      display: flex;
      flex-direction: row;
      color: #313131;

      i {
        color: #707070;
      }

      label {
        font-size: 19px;
      }
    }

    .details {
      color: #585858;

      label {
        font-size: 15px;
      }
    }

    &:hover {
      .title {
        color: #5a6080;

        i {
          color: #5d648d;
        }
      }

      .details {
        color: #676f9c;
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .items {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 8px;

    .item {
      height: auto;
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 8px;
      padding: 10px;

      .title {
        margin-bottom: 2px;
      }

      .details {
        label {
          margin-left: 30px;
          display: block;
        }
      }
    }
  }
}

</style>
