<script>
import { endOfDay, startOfDay, differenceInDays, subDays, addDays } from 'date-fns'
import { DateRange } from '@stellacontrol/model'
import { FormMixin } from '@stellacontrol/client-utilities'

export default {
  mixins: [
    FormMixin
  ],

  props: {
    // Initial value
    modelValue: {
    },
    // Maximal number of days which user can select
    maxDays: {
      default: 1
    }
  },

  data () {
    return {
      // Period start
      from: null,
      // Period end
      to: null
    }
  },

  computed: {
    // Date range
    range () {
      const { from, to } = this
      if (from && to) {
        return new DateRange({ from, to })
      }
    },

    // Period length in days
    periodLength () {
      return this.range?.length
    },

    // Period label
    periodLabel () {
      const { periodLength } = this
      if (periodLength != null) {
        if (periodLength === 1) {
          return 'One day'
        } else {
          return `${periodLength} days`
        }
      }
    },

    // Indicates whether user is allowed to go forward
    canGoLater () {
      const { to } = this
      const today = endOfDay(new Date())
      return to && endOfDay(to) < endOfDay(today)
    }
  },

  methods: {
    // Changes period start
    setFrom () {
      // If `to` is now behind `from`, adjust.
      // If `to` is too far ahead, select just one day.
      if (this.to < this.from || differenceInDays(this.to, this.from) > this.maxDays) {
        this.to = endOfDay(addDays(this.from, 1))
      }
      this.$emit('update:model-value', new DateRange({ from: this.from, to: this.to }))
    },

    // Changes period end
    setTo () {
      // If `from` is now ahead of `to`, adjust.
      // If `from` is too far behind, select just one day.
      if (this.from > this.to || differenceInDays(this.to, this.from) > this.maxDays) {
        this.from = startOfDay(subDays(this.to, 1))
      }
      this.$emit('update:model-value', new DateRange({ from: this.from, to: this.to }))
    },

    // Changes period to today
    today () {
      const period = DateRange.today()
      this.from = period.from
      this.to = period.to
      this.$emit('update:model-value', period)
    },

    // Moves period back by its current length
    back () {
      const { periodLength } = this
      if (periodLength > 0) {
        const period = this.range.back(periodLength)
        this.from = period.from
        this.to = period.to
        this.$emit('update:model-value', period)
      }
    },

    // Moves period forward by its current length
    forward () {
      const { periodLength } = this
      if (periodLength > 0) {
        const period = this.range.forward(periodLength)
        this.from = period.from
        this.to = period.to
        this.$emit('update:model-value', period)
      }
    }
  },

  watch: {
    modelValue (newValue, currentValue) {
      if (newValue?.sameAs(currentValue)) return
      this.from = newValue?.from
      this.to = newValue?.to
    }
  },

  created () {
    const { modelValue } = this
    this.from = modelValue?.from
    this.to = modelValue?.to
  }
}
</script>

<template>
  <div class="row items-center q-mb-sm">
    <label>
      Show period:
    </label>
    <sc-hint>
      You can select up to {{ maxDays }} days.
      <br>
      Use the arrows to move forward or backward.
    </sc-hint>
  </div>

  <sc-date-input class="q-mt-sm" bg-color="white" dense label="From"
    v-model="from"
    readonly
    :rules="[rules.required('Date is required')]"
    @update:model-value="value => setFrom(value)">
  </sc-date-input>

  <sc-date-input class="q-mt-sm" bg-color="white" dense label="Until"
    v-model="to"
    readonly
    :rules="[rules.required('Date is required')]"
    @update:model-value="value => setTo(value)" >
  </sc-date-input>

  <div class="row justify-center q-gutter-xs q-pa-sm">
    <q-btn dense unelevated icon="chevron_left" @click="back()">
      <sc-tooltip>Go {{ lowercase(periodLabel) }} back</sc-tooltip>
    </q-btn>
    <q-btn dense no-caps unelevated label="Today" @click="today()"></q-btn>
    <q-btn dense unelevated icon="chevron_right" @click="forward()" :disabled="!canGoLater">
      <sc-tooltip>Go {{ lowercase(periodLabel) }} forward</sc-tooltip>
    </q-btn>
  </div>

</template>

<style scoped lang="scss">

</style>