<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import OrganizationProfileGeneral from './organization-profile-general.vue'
import OrganizationProfilePermissions from './organization-profile-permissions.vue'
import OrganizationProfileOrganizations from './organization-profile-organizations.vue'
import { Secure, Permissions } from '../../components'
import { resolveOrganizationProfile } from './organization-profile.resolve'

const name = 'organization-profile'

/**
 * Organization Profile editor
 */
export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-organization-profile-general': OrganizationProfileGeneral,
    'sc-organization-profile-permissions': OrganizationProfilePermissions,
    'sc-organization-profile-organizations': OrganizationProfileOrganizations
  },

  data () {
    return {
      name,
      permissions: null
    }
  },

  computed: {
    ...mapState({
      // Data of the created or updated organization profile
      lastUpdated: state => state.organizationProfiles.lastUpdated
    }),

    ...mapGetters([
      'features',
      'environment',
      'isSmallScreen'
    ]),

    forms () {
      return [
        'general'
      ]
    }
  },

  methods: {
    ...mapActions([
      'saveOrganizationProfile',
      'editOrganizationProfile',
      'editOrganizationProfiles',
      'gotoLogin',
      'gotoRoute'
    ]),

    async save () {
      if (await this.validate()) {
        // Make sure the permissions have been stored in the organization profile
        this.permissions.apply()
        const { isNew } = this.data
        if (await this.saveOrganizationProfile({ organizationProfile: this.data })) {
          if (isNew) {
            this.editOrganizationProfile({ organizationProfile: this.lastUpdated })
          }
          return true
        }
      }
      this.selectTab('general')
    },

    async cancel () {
      await this.editOrganizationProfiles()
    },

    // Resets the view to original shape,
    // as when another organization is loaded
    async reset () {
      // Switch to default tab if one specified in URL has become invisible
      this.ensureTab()
      // Instantiate the permissions controller and populate with features
      // and permissions of the currently edited principal
      if (this.currentUser) {
        this.permissions = new Permissions(this.features, this.data, this.environment, this.currentUser, this.guardian)
      } else {
        this.gotoLogin()
      }
    }
  },

  created () {
    this.reset()
    if (this.data.isNew) {
      this.selectTab('general')
    }
  },

  // Reload data on navigation to another profile
  async beforeRouteUpdate (to, from, next) {
    if (await resolveOrganizationProfile({ from, to })) {
      this.reset()
      next()
    }
  }
}

</script>

<template>
  <sc-view :name="name" :title="data.fullName" v-if="isLoggedIn">

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <span class="text-body1 q-mx-sm text-white">
        {{ data.fullName }}
      </span>

      <q-space>
      </q-space>
      <div class="row items-center no-wrap q-gutter-sm">
        <q-btn outline style="color: white" icon="arrow_back"  @click="gotoRoute({ name: 'organization-profiles' })" />
      </div>
    </teleport>

    <template #toolbar>
      <q-btn label="Save" unelevated class="primary q-ml-lg" @click="save()"></q-btn>
      <q-btn label="Close" unelevated @click="cancel()"></q-btn>
    </template>

    <sc-tabs :model-value="viewTab" @update:model-value="tab => selectTab(tab)" :tabs="viewTabs">
      <q-tab name="general" icon="create" label="Details" :ripple="false"></q-tab>
      <q-tab name="permissions" icon="security" label="Permissions" :ripple="false"></q-tab>
      <q-tab name="organizations" icon="home_work" label="Organizations" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels :model-value="viewTab" :tabs="viewTabs">
      <q-tab-panel name="general">
        <sc-organization-profile-general :data="data" :permissions="permissions" ref="general"></sc-organization-profile-general>
      </q-tab-panel>
      <q-tab-panel name="permissions">
        <sc-organization-profile-permissions :data="data" :permissions="permissions" ref="permission"></sc-organization-profile-permissions>
      </q-tab-panel>
      <q-tab-panel name="organizations">
        <sc-organization-profile-organizations :data="data" ref="organizations"></sc-organization-profile-organizations>
      </q-tab-panel>
    </sc-tab-panels>

  </sc-view>
</template>

<style lang='scss' scoped>
</style>
