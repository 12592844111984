<script>
import { mapState, mapGetters } from 'vuex'
import { CommonAPI, SecurityAPI, DeviceAPI, AlertsAPI, NotificationsAPI, ServiceManagementAPI, DeviceTransmissionAPI, PlannerAPI } from '@stellacontrol/client-api'

const name = 'about'

export default {
  data () {
    return {
      name,
      services: []
    }
  },

  computed: {
    ...mapState({
      environment: state => state.client.environment,
      application: state => state.client.application,
      configuration: state => state.client.configuration,
      environments: state => state.client.configuration.environments
    }),

    ...mapGetters({
      company: 'company',
      user: 'currentUser',
      organization: 'currentOrganization',
      isProductionEnvironment: 'isProductionEnvironment'
    }),

    title () {
      return `About ${this.application.name} application`
    },

    groups () {
      const { services, application, environment, user, organization, isProductionEnvironment } = this
      if (services.length === 0) return []

      const CommonAPI = services.find(s => s.name === 'Common')
      const { database } = CommonAPI
      const groups = [
        {
          title: 'General Information',
          items: [
            { label: 'Application', value: application.name },
            { label: 'Version', value: application.version },
            { label: 'Environment', value: environment.toUpperCase(), isVisible: user.isSuperAdministrator || !isProductionEnvironment },
            { label: 'Current Organization', value: organization.name },
            { label: 'Organization Profile', value: organization.profile.fullName, isVisible: !organization.isRegularOrganization },
            { label: 'Current User', value: user.fullName },
            { label: 'User E-Mail', value: user.name }
          ],
          isVisible: true
        },
        {
          title: 'Database',
          items: [
            { label: 'Application', value: `[${database.application.type.toUpperCase()}] ${database.application.description}` },
            { label: 'Status/History', value: `[${database.statushistory.type.toUpperCase()}] ${database.statushistory.description}` },
            { label: 'Statistics', value: `[${database.statistics.type.toUpperCase()}] ${database.statistics.description}` },
          ],
          isVisible: this.user.isSuperAdministrator
        },
        {
          title: 'Services',
          items: services.map(service => ({
            label: service.name,
            value: service.isOnline ? (service.version ? `v.${service.version}` : 'online') : 'offline',
            details: service.url,
            isSuccess: service.isOnline,
            isError: !service.isOnline,
            tooltip: service.error ? service.error.toString() : ''
          })),
          isVisible: this.user.isSuperAdministrator
        }
      ]

      return groups.filter(g => g.isVisible)
    },

    getItemClass () {
      return item => ({
        'text-red-9': item.isError,
        'text-green-9': item.isSuccess,
        'text-indigo-9': !(item.isError || item.isSuccess)
      })
    }
  },

  methods: {
    /**
     * Returns the status of API services used by the application
     */
    async pollServices () {
      this.services = []
      // Internal APIs
      for (const api of [CommonAPI, SecurityAPI, DeviceAPI, AlertsAPI, NotificationsAPI, ServiceManagementAPI, DeviceTransmissionAPI, PlannerAPI]) {
        const status = await api.getStatus({ suppressExceptions: true })
        this.services.push(status)
      }
    }
  },

  created () {
    this.pollServices()
  }
}

</script>

<template>
  <q-banner v-if="!isProductionEnvironment" class="bg-orange-6 row items-center">
    <q-icon color="white" name="new_releases" class="q-mr-md" size="md" />
    <span class="text-subtitle2">
      You are now logged in to TEST environment, which is less stable than PRODUCTION
      environment.
      Use it on your own responsibility, otherwise log in to PRODUCTION environment
      at <a :href="environments.production.url">{{ environments.production.url }}</a>.
    </span>
  </q-banner>

  <main class="q-pa-lg">
    <div class="group" v-for="group in groups" :key="group.title">
      <header>
        {{ group.title }}
      </header>

      <section class="q-gutter-sm items">
        <table>
          <template v-for="item in group.items">
            <tr class="item" :key="item.label" v-if="item.isVisible !== false">
              <td class="label text-grey-8">
                {{ item.label }}:
              </td>
              <td class="value" :class="getItemClass(item)">
                {{ item.value }}
              </td>
              <td class="details" :class="getItemClass(item)">
                {{ item.details }}
              </td>
              <sc-tooltip :text="item.tooltip" v-if="item.tooltip" />
            </tr>
          </template>
        </table>
      </section>
    </div>
  </main>
</template>

<style lang='scss' scoped>
main {
  flex: 1;
  overflow: auto;
  position: relative;

  .logo {
    position: absolute;
    right: 16px;
    bottom: 16px;

    .logo-image {
      width: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: right;
    }
  }

  .group {
    margin-bottom: 24px;

    header {
      font-size: 22px;
      padding-bottom: 10px;
      padding-left: 2px;
    }

    .items {
      .item {
        font-size: 15px;

        td {
          padding-right: 16px;

          &.label {
            width: 220px;
          }
        }
      }
    }
  }
}
</style>
