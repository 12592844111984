import { reactive } from 'vue'

const DESKTOP_MODE_KEY = 'force-desktop-mode'

/**
 * Viewport service
 */
export const Viewport = reactive({
  /**
  * Typical screen sizes
  */
  VGA: {
    width: 640,
    height: 480
  },
  SVGA: {
    width: 800,
    height: 600
  },
  XGA: {
    width: 1024,
    height: 768
  },
  HD: {
    width: 1366,
    height: 768
  },
  HDPlus: {
    width: 1600,
    height: 900
  },
  FullHD: {
    width: 1920,
    height: 1080
  },

  /**
   * Current viewport width
   * @type {Number}
   */
  width: 0,

  /**
   * Current viewport height
   * @type {Number}
   */
  height: 0,

  /**
   * Indicates whether current window size is within mobile phone view range
   * @type {Boolean}
   */
  isMobilePhone: false,

  /**
   * Indicates that mobile phone has been detected.
   * Useful, when {@link isMobileMode} has been suppressed by user forcing desktop mode,
   * yet we need to know that we're on a mobile screen now.
   */
  mobilePhoneDetected: false,

  /**
   * Indicates whether current window size qualifies as small screen - phone, tablet, old laptop
   * @type {Boolean}
   */
  isSmallScreen: false,

  /**
   * Indicates that small screen has been detected.
   * Useful, when {@link isSmallScreen} has been suppressed by user forcing desktop mode,
   * yet we need to know that we're on a small screen now.
   */
  smallScreenDetected: false,

  /**
   * Indicates whether current window size is within HD view range
   * @type {Boolean}
   */
  atLeastHD: false,

  /**
   * Indicates whether current window size is within HD+ view range
   * @type {Boolean}
   */
  atLeastHDPlus: false,

  /**
   * Indicates whether current window size is within Full HD view range
   * @type {Boolean}
   */
  atLeastFullHD: false,

  /**
   * Indicates whether current window size is within desktop view range
   * @type {Boolean}
   */
  isNormalScreen () {
    return !(this.isSmallScreen || this.isMobilePhone)
  },

  /**
   * Indicates whether current window size is a wide screen
   * @type {Boolean}
   */
  isWideScreen () {
    return this.atLeastFullHD
  },

  /**
   * Returns document body element
   */
  body () {
    return (window.document || {}).body
  },

  /**
   * Returns true if current window size is equal or above the specified one
   */
  isWidthAtLeast (value) {
    return this.body()?.offsetWidth >= value
  },

  /**
   * Returns true if current window size is equal or below the specified one
   */
  isWidthAtMost (value) {
    return this.body()?.offsetWidth <= value
  },

  /**
   * Returns true if current window size is below the specified one
   */
  isWidthBelow (value) {
    return this.body()?.offsetWidth < value
  },

  /**
   * Returns true if current window size is above the specified one
   */
  isWidthAbove (value) {
    return this.body()?.offsetWidth > value
  },

  /**
   * Checks whether desktop mode has been forced by user
   * @type {Boolean}
   */
  get isDesktopModeForced () {
    return Boolean(window.localStorage.getItem(DESKTOP_MODE_KEY))
  },

  /**
   * Forces desktop mode, if mobile screen detected
   */
  forceDesktopMode () {
    window.localStorage.setItem(DESKTOP_MODE_KEY, true)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  },

  /**
   * Reverts back to automatic mode
   */
  resetDesktopMode () {
    window.localStorage.removeItem(DESKTOP_MODE_KEY)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }
})


// Checks the current screen size
function checkScreenSize () {
  // Determine window size
  Viewport.width = document.body.offsetWidth
  Viewport.height = document.body.offsetHeight

  // Detect small screen, but suppress if user forced desktop mode
  Viewport.mobilePhoneDetected = Viewport.isWidthAtMost(Viewport.XGA.width)
  Viewport.smallScreenDetected = Viewport.isWidthAtMost(Viewport.HD.width - 1)
  const isMobilePhone = Viewport.isDesktopModeForced ? false : Viewport.mobilePhoneDetected
  const isSmallScreen = Viewport.isDesktopModeForced ? false : Viewport.smallScreenDetected

  // Detect normal screen, force it if user forced desktop mode
  const atLeastHD = Viewport.isDesktopModeForced ? true : Viewport.isWidthAtLeast(Viewport.HD.width)
  const atLeastHDPlus = Viewport.isWidthAtLeast(Viewport.HDPlus.width)
  const atLeastFullHD = Viewport.isWidthAtLeast(Viewport.FullHD.width)

  if (Viewport.isMobilePhone !== isMobilePhone) {
    Viewport.isMobilePhone = isMobilePhone
  }

  if (Viewport.isSmallScreen !== isSmallScreen) {
    Viewport.isSmallScreen = isSmallScreen
  }

  if (Viewport.atLeastHD !== atLeastHD) {
    Viewport.atLeastHD = atLeastHD
  }

  if (Viewport.atLeastHDPlus !== atLeastHDPlus) {
    Viewport.atLeastHDPlus = atLeastHDPlus
  }

  if (Viewport.atLeastFullHD !== atLeastFullHD) {
    Viewport.atLeastFullHD = atLeastFullHD
  }

  return { isMobilePhone, isSmallScreen, atLeastHD, atLeastHDPlus, atLeastFullHD }
}

window.addEventListener('load', () => {
  checkScreenSize()
})

window.addEventListener('resize', () => {
  checkScreenSize()
})
